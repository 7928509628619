<template>
  <div class="interface"   >
    <video-finite-interface-desktop
        v-if="$windowWidth > 992"
        :volume="volume"
        :speed="speed"
        :current-time="currentTime"
        :has-audio="hasAudio"
        :time-end="timeEnd"
        :zoom="zoom"
        :stop="stop"
        :video="video"
        :is-visible="isVisible"
        @changeZoom="onChangeZoom"
        @exitScreen="exitFullScreen"
        @changeVolume="onChangeVolume"
        @current="onChangeCurrent"
        @changeSpeed="onChangeSpeed"
        @changePlay="onChangePlay"
    ></video-finite-interface-desktop>
    <video-finite-interface-mobile-portait
        v-else-if="isPortrait"
        :volume="volume"
        :speed="speed"
        :current-time="currentTime"
        :has-audio="hasAudio"
        :time-end="timeEnd"
        :zoom="zoom"
        :stop="stop"
        :video="video"
        :is-visible="isVisible"
        @changeZoom="onChangeZoom"
        @exitScreen="exitFullScreen"
        @changeVolume="onChangeVolume"
        @current="onChangeCurrent"
        @changeSpeed="onChangeSpeed"
        @changePlay="onChangePlay"
    ></video-finite-interface-mobile-portait>
    <video-finite-interface-mobile-horizontal
        v-else
        :volume="volume"
        :speed="speed"
        :current-time="currentTime"
        :has-audio="hasAudio"
        :time-end="timeEnd"
        :zoom="zoom"
        :stop="stop"
        :video="video"
        :is-visible="isVisible"
        @changeZoom="onChangeZoom"
        @exitScreen="exitFullScreen"
        @changeVolume="onChangeVolume"
        @current="onChangeCurrent"
        @changeSpeed="onChangeSpeed"
        @changePlay="onChangePlay"
    ></video-finite-interface-mobile-horizontal>
  </div>
</template>

<script>
import IntefaceAudio from "./interface/IntefaceAudio";
import VideoFiniteInterfaceDesktop from "./VideoFiniteInterfaceDesktop";
import VideoFiniteInterfaceMobilePortait from "./VideoFiniteInterfaceMobilePortait";
import VideoFiniteInterfaceMobileHorizontal from "./VideoFiniteInterfaceMobileHorizontal";

export default {
  name: "VideoFiniteInterface",
  components: {
    VideoFiniteInterfaceMobileHorizontal,
    VideoFiniteInterfaceMobilePortait, VideoFiniteInterfaceDesktop, IntefaceAudio},
  props: {
    video: Object,
    stop: Boolean,
    zoom: Number,
    volume: Number,
    hasAudio: Boolean,
    timeEnd:{
      default(){
        return 0
      }
    },
    currentTime:{
      default(){
        return 0
      }
    },
    speed: Number,
    isVisible: Boolean
  },
  data(){
    return {
      isPressUp: false,
      current: 0,
      isSpeedSelectorOpened: false,
      timeout: null,
    }
  },
  computed: {
    isPortrait(){
      return this.$windowWidth < this.$windowHeight
    },
    getVolumeSize() {
      return this.$store.getters.getVideoSize
    },
    duration(){
      return this.videoTime(this.timeEnd)
    },
    currentValue(){
      if (this.current === 0){
        return 0
      }
      if (!this.isPressUp){
        return (Math.floor(this.currentTime) / (Math.floor(this.timeEnd) / 100))
      } else{
        return;
      }
    }
  },
  methods: {

    disableVolume(e){
      console.log(e,'disable')
      this.changeVolume(0)
    },
    enableVolume(e){
      console.log(e,'enable')
      this.changeVolume(1)
    },
    changeZoomTouch(zoom){
      return (direction,event) =>{
        this.$emit('changeZoom', zoom)
      }
    },

    play(isPlay){
      return (direction,event) =>{
        this.$emit('changePlay', isPlay)
      }
    },

    changeAudioSize(e) {
      const val = e.target.value
      this.$store.commit('changeVideoVolume', val)
      let hasAudioPlayer = this.$store.getters.getVideo.volume
      if (val) {
        if (!hasAudioPlayer) {
          this.changeVideoVolume(true)
        }
      } else {
        if (hasAudioPlayer) {
          this.changeVideoVolume(false)
        }
      }
    },
    changeVideoVolume(type) {
      if (type) {
        this.$store.commit('changeVideo', {volume: this.video.id, volumeSize: 1})
      } else {
        this.$store.commit('changeVideo', {volume: null, volumeSize: 0})
      }

    },
    changeVolume(size){
      document.documentElement.style.setProperty('--range', (size * 100) + '%')
      this.$emit("changeVolume",size)
    },
    changeVolumeMobile(e){
      this.changeVolume(e.target.value)
    },
    videoTime(time) { //Рассчитываем время в секундах и минутах
      time = Math.floor(time);
      var minutes = Math.floor(time / 60);
      var seconds = Math.floor(time - minutes * 60);
      var minutesVal = minutes;
      var secondsVal = seconds;
      if(minutes < 10) {
        minutesVal = '0' + minutes;
      }
      if(seconds < 10) {
        secondsVal = '0' + seconds;
      }
      return minutesVal + ':' + secondsVal;
    },

    onMouseUp(e){
      this.isPressUp = false
      console.log("up",e)
      this.$emit("current",(this.timeEnd/100) * e.target.value)
    },

    onMouseDown(e){
      this.isPressUp = true
      console.log("down",e)
    },

    toggleSpeedSelect(){
      console.log("toogle")
      this.isSpeedSelectorOpened = !this.isSpeedSelectorOpened
    },

    onChangeSpeedTouch(speed){
      return (direction, event) => {
        this.toggleSpeedSelect();
        this.$emit("changeSpeed",speed)
        // do something ~
      }
    },

    modifyPlayline(seconds){
      this.$emit("current",((this.timeEnd/100) * this.$refs.playline.value)+seconds)
    },

    modifyPlaylineTouch (seconds) {
      return (direction, event) => {
        this.$emit("current",((this.timeEnd/100) * this.$refs.playline.value)+seconds)
        // do something ~
      }
    },












    onChangeZoom(zoom){
      this.$emit("changeZoom",zoom)
    },

    exitFullScreen(){
      this.$emit("exitScreen")
    },

    onChangeVolume(volume){
      this.$emit("changeVolume",volume)
    },

    onChangeCurrent(current){
      this.$emit("current",current)
    },

    onChangeSpeed(speed){
      this.$emit("changeSpeed",speed)
    },

    onChangePlay(isPlay){
      console.log("changePlay",isPlay)
      this.$emit("changePlay",isPlay)
    },
  },
  watch: {
    volume(size){
      console.log(size)
      document.documentElement.style.setProperty('--range', (size * 100) + '%')
    },
  },
  mounted() {
    document.documentElement.style.setProperty('--range', (this.volume * 100) + '%')
    document.documentElement.style.setProperty('--playline', (this.currentValue) + '%')
  },

}
</script>

<style scoped lang="scss">
.interface{
  opacity: 1;
  transition: all 1s;

  &_hidden{
    opacity: 0;
    pointer-events: none;
  }
}
</style>
