<template>
  <div class="interface">
    <div
        class="interface__wrapper interface__wrapper_desktop"
        v-if="$windowWidth > 992"
          >
      <div class="interface__top">
        <div class="interface__left">
          <inteface-audio
              :volume="volume"
              :has-audio="hasAudio"
              @changeVideoVolume="changeVideoVolume"
              @changeVolume="changeVolume"></inteface-audio>
        </div>
        <div class="interface__center">
          <div class="interface__pause" v-if="!stop" @click="$emit('changePlay', true)">
            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M1 0C0.447715 0 0 0.447715 0 1V19C0 19.5523 0.447715 20 1 20H5C5.55228 20 6 19.5523 6 19V1C6 0.447715 5.55228 0 5 0H1ZM13 0C12.4477 0 12 0.447715 12 1V19C12 19.5523 12.4477 20 13 20H17C17.5523 20 18 19.5523 18 19V1C18 0.447715 17.5523 0 17 0H13Z" fill="#E2E2E2"/>
            </svg>
          </div>
          <div class="interface__play" v-else @click="$emit('changePlay', false)">
            <svg width="19" height="24" viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17.4884 9.5282C19.2217 10.7203 19.2217 13.2797 17.4884 14.4718L5.42705 22.7674C3.43659 24.1364 0.72699 22.7114 0.72699 20.2956L0.72699 3.70442C0.72699 1.28862 3.43659 -0.136384 5.42705 1.23262L17.4884 9.5282Z" fill="#E2E2E2"/>
            </svg>
          </div>
        </div>
        <div class="interface__right">
          <div class="interface__zoom" :class="zoom === 1 ? 'interface__zoom_hide' : ''" @click="$emit('changeZoom', 1)">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="19px" height="19px" viewBox="0 0 18 18" version="1.1">
              <title>Combined Shape</title>
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <path d="M7.4825447,0 C9.48210047,0 11.3620877,0.780525986 12.7759825,2.19780839 C15.1893429,4.61684408 15.6070831,8.29010645 14.029578,11.1436443 L17.4067957,14.5288666 C18.1977348,15.3216656 18.1977348,16.6116468 17.4067957,17.4044458 C17.0236642,17.788541 16.5142797,18 15.9724106,18 C15.4305415,18 14.921157,17.788541 14.5380254,17.4044458 L11.1758007,14.0343143 C10.0623265,14.6693175 8.79836084,15.0073262 7.4825447,15.0073262 C5.48292647,15.0073262 3.60300171,14.2268629 2.18910688,12.8095805 C-0.729702293,9.88384471 -0.729702293,5.12348153 2.18910688,2.19780839 C3.60306418,0.780525986 5.48298894,0 7.4825447,0 Z M7.48248223,1.87852223 C5.98350258,1.87852223 4.5741681,2.46361929 3.51423113,3.52611146 C1.32620186,5.71934878 1.32620186,9.28804008 3.51423113,11.4812774 C4.57423057,12.543707 5.98350258,13.128804 7.48248223,13.128804 C8.98146188,13.128804 10.3907964,12.543707 11.4507333,11.4812774 C13.6387626,9.28804008 13.6387626,5.71928616 11.4507333,3.52611146 C10.3907339,2.46361929 8.98146188,1.87852223 7.48248223,1.87852223 Z M9.82922196,6.92413052 C10.1996824,6.92413052 10.5,7.25991696 10.5,7.67413052 C10.5,8.05382629 10.2476498,8.36762148 9.92024257,8.4172839 L9.82922196,8.42413052 L5.17077804,8.42413052 C4.80031756,8.42413052 4.5,8.08834408 4.5,7.67413052 C4.5,7.29443475 4.75235017,6.98063956 5.07975743,6.93097714 L5.17077804,6.92413052 L9.82922196,6.92413052 Z" id="Combined-Shape" fill="#E2E2E2" fill-rule="nonzero"/>
              </g>
            </svg>
          </div>
          <div class="interface__close" @click="$emit('exitScreen')">
            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.454892 14.5451C1.05653 15.1467 2.03198 15.1467 2.63362 14.5451L7.5 9.67873L12.3664 14.5451C12.968 15.1467 13.9435 15.1467 14.5451 14.5451C15.1467 13.9435 15.1467 12.968 14.5451 12.3664L9.67873 7.5L14.5451 2.63362C15.1467 2.03198 15.1467 1.05653 14.5451 0.454893C13.9435 -0.146747 12.968 -0.146747 12.3664 0.454892L7.5 5.32127L2.63362 0.454893C2.03198 -0.146746 1.05653 -0.146747 0.454893 0.454893C-0.146747 1.05653 -0.146747 2.03198 0.454892 2.63362L5.32127 7.5L0.454893 12.3664C-0.146747 12.968 -0.146747 13.9435 0.454892 14.5451Z" fill="white" fill-opacity="0.8"/>
              </g>
              <defs>
                <filter id="filter0_d" x="0.00366211" y="0.00390625" width="18.9927" height="18.9927" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset dx="2" dy="2"/>
                  <feGaussianBlur stdDeviation="1"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                </filter>
              </defs>
            </svg>
          </div>
        </div>
      </div>
      <div class="interface__bottom">
        <p>Прямая трансляция</p>
      </div>
    </div>
    <div class="interface__wrapper interface__wrapper_mobile" v-else>
      <div class="interface-mobile__play">
        <svg
            v-if="!stop"
            @click="$emit('changePlay', true)"
            v-touch="play(true)"
            width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M1 0C0.447715 0 0 0.447715 0 1V19C0 19.5523 0.447715 20 1 20H5C5.55228 20 6 19.5523 6 19V1C6 0.447715 5.55228 0 5 0H1ZM13 0C12.4477 0 12 0.447715 12 1V19C12 19.5523 12.4477 20 13 20H17C17.5523 20 18 19.5523 18 19V1C18 0.447715 17.5523 0 17 0H13Z" fill="#E2E2E2"/>
        </svg>
        <svg
            v-else
            @click="$emit('changePlay', false)"
            v-touch="play(false)"
            width="19" height="24" viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M17.4884 9.5282C19.2217 10.7203 19.2217 13.2797 17.4884 14.4718L5.42705 22.7674C3.43659 24.1364 0.72699 22.7114 0.72699 20.2956L0.72699 3.70442C0.72699 1.28862 3.43659 -0.136384 5.42705 1.23262L17.4884 9.5282Z" fill="#E2E2E2"/>
        </svg>
        <p>Прямая трансляция</p>
      </div>
      <div class="interface-mobile__top">
        <div class="interface-mobile__close"
             @click="$emit('exitScreen')"
             v-touch="this.exitFullScreen">
          <svg
              width="15px" height="15px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <title>Combined Shape</title>
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" fill-opacity="0.8">
              <g id="Управленец-4-Copy" transform="translate(-45.000000, -59.000000)" fill="#FFFFFF">
                <path d="M59.4273874,59.3484677 L59.5451076,59.4548924 C60.1467471,60.0565319 60.1494552,61.0292742 59.5405613,61.6381681 L54.679,66.5 L59.5405613,71.3618319 C60.1069522,71.9282229 60.1478272,72.8190541 59.6515323,73.4273874 L59.5451076,73.5451076 C58.9434681,74.1467471 57.9707258,74.1494552 57.3618319,73.5405613 L52.5,68.679 L47.6381681,73.5405613 C47.0717771,74.1069522 46.1809459,74.1478272 45.5726126,73.6515323 L45.4548924,73.5451076 C44.8532529,72.9434681 44.8505448,71.9707258 45.4594387,71.3618319 L50.321,66.5 L45.4594387,61.6381681 C44.8930478,61.0717771 44.8521728,60.1809459 45.3484677,59.5726126 L45.4548924,59.4548924 C46.0565319,58.8532529 47.0292742,58.8505448 47.6381681,59.4594387 L52.5,64.321 L57.3618319,59.4594387 C57.9282229,58.8930478 58.8190541,58.8521728 59.4273874,59.3484677 Z" id="Combined-Shape"></path>
              </g>
            </g>
          </svg>
        </div>
        <div class="interface-mobile__zoom"
             @click="$emit('changeZoom', 1)"
             v-touch="changeZoomTouch(1)">
          <svg
              :class="zoom === 1 ? 'interface__zoom_hide' : ''"
              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="19px" height="19px" viewBox="0 0 18 18" version="1.1">
            <title>Combined Shape</title>
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <path d="M7.4825447,0 C9.48210047,0 11.3620877,0.780525986 12.7759825,2.19780839 C15.1893429,4.61684408 15.6070831,8.29010645 14.029578,11.1436443 L17.4067957,14.5288666 C18.1977348,15.3216656 18.1977348,16.6116468 17.4067957,17.4044458 C17.0236642,17.788541 16.5142797,18 15.9724106,18 C15.4305415,18 14.921157,17.788541 14.5380254,17.4044458 L11.1758007,14.0343143 C10.0623265,14.6693175 8.79836084,15.0073262 7.4825447,15.0073262 C5.48292647,15.0073262 3.60300171,14.2268629 2.18910688,12.8095805 C-0.729702293,9.88384471 -0.729702293,5.12348153 2.18910688,2.19780839 C3.60306418,0.780525986 5.48298894,0 7.4825447,0 Z M7.48248223,1.87852223 C5.98350258,1.87852223 4.5741681,2.46361929 3.51423113,3.52611146 C1.32620186,5.71934878 1.32620186,9.28804008 3.51423113,11.4812774 C4.57423057,12.543707 5.98350258,13.128804 7.48248223,13.128804 C8.98146188,13.128804 10.3907964,12.543707 11.4507333,11.4812774 C13.6387626,9.28804008 13.6387626,5.71928616 11.4507333,3.52611146 C10.3907339,2.46361929 8.98146188,1.87852223 7.48248223,1.87852223 Z M9.82922196,6.92413052 C10.1996824,6.92413052 10.5,7.25991696 10.5,7.67413052 C10.5,8.05382629 10.2476498,8.36762148 9.92024257,8.4172839 L9.82922196,8.42413052 L5.17077804,8.42413052 C4.80031756,8.42413052 4.5,8.08834408 4.5,7.67413052 C4.5,7.29443475 4.75235017,6.98063956 5.07975743,6.93097714 L5.17077804,6.92413052 L9.82922196,6.92413052 Z" id="Combined-Shape" fill="#E2E2E2" fill-rule="nonzero"/>
            </g>
          </svg>
        </div>
      </div>
      <div class="interface-mobile__audio" :class="{'_disabled' : !hasAudio}">
        <div class="interface-mobile__wrap">
          <svg
              v-show="volume"
              v-touch:tap="disableVolume"
              width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9273 16.2051C17.1867 16.1941 17.429 16.0753 17.5965 15.8773C19.4048 13.7353 20.4004 11.0111 20.4 8.20695C20.4 5.29247 19.335 2.48807 17.4015 0.311207C17.2321 0.120446 16.9909 0.00774375 16.7359 0.000508001C16.4673 -0.00804334 16.2331 0.0917223 16.0528 0.272835L15.9314 0.394528C15.5863 0.740967 15.5698 1.29593 15.8941 1.66254C17.4936 3.46841 18.3749 5.79262 18.3749 8.20695C18.3749 10.5281 17.5517 12.7848 16.0577 14.5617C15.7485 14.9296 15.7715 15.4732 16.1116 15.813L16.2333 15.9347C16.4076 16.109 16.6431 16.206 16.8885 16.206C16.9016 16.206 16.9146 16.2055 16.9273 16.2051ZM10.2061 14.4619C10.2061 14.8213 9.9985 15.1478 9.67354 15.3002C9.5479 15.3596 9.41328 15.3883 9.27952 15.3883C9.06771 15.3883 8.85788 15.3155 8.68839 15.175L3.6238 10.9782H0.926396C0.41485 10.9787 0 10.5636 0 10.0521V6.36117C0 5.84941 0.41485 5.43456 0.926396 5.43456H3.62402L8.6886 1.23782C8.96532 1.00847 9.34903 0.959574 9.67376 1.11306C9.9985 1.26545 10.2064 1.59215 10.2064 1.95131L10.2061 14.4619ZM13.7663 13.0143C13.7438 13.0159 13.7221 13.0168 13.6997 13.0168C13.455 13.0168 13.2191 12.9201 13.0447 12.7455L12.9209 12.6212C12.5959 12.2969 12.5578 11.7832 12.8314 11.4144C13.5252 10.479 13.8913 9.37015 13.8913 8.20695C13.8913 6.95582 13.4756 5.78231 12.6889 4.81316C12.3894 4.4448 12.417 3.90979 12.7527 3.57431L12.8764 3.45043C13.0614 3.26537 13.3094 3.16451 13.5778 3.18051C13.8391 3.19367 14.0832 3.31668 14.249 3.51928C15.3402 4.8546 15.9167 6.47585 15.9167 8.20717C15.9167 9.81964 15.4069 11.3545 14.4421 12.6451C14.281 12.8602 14.0345 12.9953 13.7663 13.0143Z" fill="#E2E2E2"/>
          </svg>
          <svg
              v-show="!volume"
              v-touch:tap="enableVolume"
              width="10"
              height="11"
              viewBox="0 0 10 11"
              fill="none"
              class="audio__off"
              xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M1.1508 0.164386C0.88495 -0.059936 0.48743 -0.0564106 0.225207 0.185641C-0.0588671 0.447864 -0.0765815 0.890725 0.185641 1.1748L8.58564 10.2748L8.64921 10.3356C8.91506 10.5599 9.31258 10.5564 9.5748 10.3144C9.85887 10.0521 9.87659 9.60928 9.61437 9.32521L1.21437 0.225207L1.1508 0.164386ZM0.64848 3.80419H2.14531L7.14432 9.15941L7.1443 10.1234C7.1443 10.3749 6.99895 10.6035 6.77148 10.7101C6.68354 10.7517 6.5893 10.7718 6.49567 10.7718C6.3474 10.7718 6.20052 10.7209 6.08187 10.6225L2.53666 7.68477H0.64848C0.290398 7.68508 3.4942e-06 7.39453 3.4942e-06 7.03645V4.45282C3.4942e-06 4.09459 0.290398 3.80419 0.64848 3.80419ZM7.14445 1.36592L7.14437 6.08199L3.93587 2.64487L6.08203 0.866479C6.27573 0.705933 6.54433 0.671705 6.77164 0.779145C6.99895 0.885818 7.14445 1.11451 7.14445 1.36592Z"
                  fill="#E2E2E2"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import IntefaceAudio from "./interface/IntefaceAudio";

    export default {
        name: "VideoInterface",
        components: {IntefaceAudio},
        props: {
            video: Object,
            stop: Boolean,
            zoom: Number,
          volume: Number,
          hasAudio: Boolean
        },
        computed: {
            getVolumeSize() {
                return this.$store.getters.getVideoSize
            }
        },
        methods: {

          disableVolume(e){
            console.log(e,'disable')
            this.changeVolume(0)
          },
          enableVolume(e){
            console.log(e,'enable')
            this.changeVolume(1)
          },
          changeZoomTouch(zoom){
            return (direction,event) =>{
              this.$emit('changeZoom', zoom)
            }
          },
          exitFullScreen(){
            this.$emit("exitScreen")
          },
          play(isPlay){
            return (direction,event) =>{
              this.$emit('changePlay', isPlay)
            }
          },

            changeAudioSize(e) {
                const val = e.target.value
                this.$store.commit('changeVideoVolume', val)
                let hasAudioPlayer = this.$store.getters.getVideo.volume
                if (val) {
                    if (!hasAudioPlayer) {
                        this.changeVideoVolume(true)
                    }
                } else {
                    if (hasAudioPlayer) {
                        this.changeVideoVolume(false)
                    }
                }
            },
            changeVideoVolume(type) {
                if (type) {
                    this.$store.commit('changeVideo', {volume: this.video.id, volumeSize: 1})
                } else {
                    this.$store.commit('changeVideo', {volume: null, volumeSize: 0})
                }

            },
          changeVolume(size){
            document.documentElement.style.setProperty('--range', (size * 100) + '%')
            this.$emit("changeVolume",size)
          },
          changeVolumeMobile(e){
            this.changeVolume(e.target.value)
          },
        },
        watch: {
            volume(size){
              console.log(size)
              document.documentElement.style.setProperty('--range', (size * 100) + '%')
            }
        },
        mounted() {
            document.documentElement.style.setProperty('--range', (this.volume * 100) + '%')
        },

    }
</script>

<style scoped lang="scss">

.interface {
  &__wrapper {
    &_desktop {
      position: absolute;
      bottom: 10px;
      width: 380px;
      z-index: 999;
      background: rgba(55, 55, 55, 0.8);
      backdrop-filter: blur(16.3097px);
      border-radius: 14px;
      height: 80px;
      left: 0;
      right: 0;
      margin: 0 auto;
      padding: 12px 14px;
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__center {
    justify-self: center;
    margin: 0 auto;
    svg {
      cursor: pointer;
    }
  }
  &__left {
    width: 83px;
  }
  &__right {
    width: 83px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    svg {
      cursor: pointer;
      vertical-align: middle;
    }
  }
  &__zoom {
    margin-right: 20px;
    transition: all .5s;
    max-width: 16px;
    svg {
      position: relative;
      top: -1px;
      width: 100%;
    }
    &_hide {
      opacity: .5;
    }
  }
  &__bottom {
    display: flex;
    justify-content: center;
    p {
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      padding-top: 10px;
      color: #E2E2E2;
    }
  }
}
.interface-mobile {
  &__play {
    background: rgba(55, 55, 55, 0.8);
    backdrop-filter: blur(8.15485px);
    border-radius: 14px;
    left: 18px;
    bottom: 45px;
    padding: 10px 30px 10px 21px;
    position: absolute;
    z-index: 5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    svg {
      vertical-align: middle;
      max-width: 16px;
    }
    p {
      padding-left: 21px;
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      text-align: center;

      color: #E2E2E2;
    }
  }
  &__top {
    position: absolute;
    top: 20px;
    left: 18px;
    z-index: 5;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__close {
    background: rgba(27, 27, 27, 0.796957);
    backdrop-filter: blur(8.15485px);
    width: 50px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 14px 0px 0px 14px;
    svg {
      vertical-align: middle;
    }
  }
  &__zoom {
    background: rgba(55, 55, 55, 0.8);
    backdrop-filter: blur(16.3097px);
    border-radius: 0px 14px 14px 0px;
    width: 50px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__audio {
    position: absolute;
    top: 20px;
    transition: all .25s;
    right: 18px;
    height: 40px;
    z-index: 5;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    flex-direction: row-reverse;
    background: rgba(40, 40, 40, 0.80324);
    backdrop-filter: blur(16.3097px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 14px;

    &._disabled{
      pointer-events: none;
      opacity: .25;
      background: none;
    }
  }
  &__wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    position: relative;
    z-index: 8;
    svg {
      transform: scale(.8);
    }
    .audio__off {
      width: 16px;
      height: auto;
    }
  }
  &__input {
    position: relative;
    width: 0px;
    overflow: hidden;
    height: 40px;
    transition: all .25s;
    display: flex;
    justify-content: center;
    align-items: center;
    &_open {
      width: 120px;
    }
    input {
      appearance: none;
      position: relative;
      outline: none;
      width: 100px;
      left: 10px;
      cursor: pointer;
      height: 3px;
      //background: #E2E2E2;
      //opacity: 0.1;
      background-color: transparent;
      border-radius: 2.45px;
      overflow: hidden;

      &::before {
        position: absolute;
        content: '';
        width: var(--range);
        border-radius: 2.45px;
        height: 3px;
        background: #E2E2E2;
      }

      &::-webkit-slider-runnable-track {
        height: 3px;
        background: #E2E2E2;
        border-radius: 2.45px;
        opacity: .1;
      }

      &::-webkit-slider-thumb {
        height: 3px;
        width: 3px;
        appearance: none;
        border-radius: 2.45px;
        //background-color: #E2E2E2;
      }
    }

    label {
      background-color: #7e8bc6;
    }
  }
}
</style>
