<template>
  <div class="audio" v-if="hasAudio">
    <AudioSvg
        :is-audio="volume !== 0"
        @click.native="(volume > 0) ? changeAudio(0) : changeAudio(1)"
    />

    <div class="audio__input" v-if="hasAudio">
      <input type="range"
             min="0"
             max="1"
             step="0.01"
             id="range-value"
             @input="changeAudioSize"
             :value="volume"
      >
      <label for="range-value"></label>
    </div>
  </div>
  <div class="audio" v-else>
    <svg
        style="opacity: 0.5;pointer-events: none"
        width="10"
        height="11"
        viewBox="0 0 10 11"
        fill="none"
        class="audio__off"
        xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M1.1508 0.164386C0.88495 -0.059936 0.48743 -0.0564106 0.225207 0.185641C-0.0588671 0.447864 -0.0765815 0.890725 0.185641 1.1748L8.58564 10.2748L8.64921 10.3356C8.91506 10.5599 9.31258 10.5564 9.5748 10.3144C9.85887 10.0521 9.87659 9.60928 9.61437 9.32521L1.21437 0.225207L1.1508 0.164386ZM0.64848 3.80419H2.14531L7.14432 9.15941L7.1443 10.1234C7.1443 10.3749 6.99895 10.6035 6.77148 10.7101C6.68354 10.7517 6.5893 10.7718 6.49567 10.7718C6.3474 10.7718 6.20052 10.7209 6.08187 10.6225L2.53666 7.68477H0.64848C0.290398 7.68508 3.4942e-06 7.39453 3.4942e-06 7.03645V4.45282C3.4942e-06 4.09459 0.290398 3.80419 0.64848 3.80419ZM7.14445 1.36592L7.14437 6.08199L3.93587 2.64487L6.08203 0.866479C6.27573 0.705933 6.54433 0.671705 6.77164 0.779145C6.99895 0.885818 7.14445 1.11451 7.14445 1.36592Z"
            fill="#E2E2E2"/>
    </svg>
  </div>
</template>

<script>
import AudioSvg from "../../../assets/vue-icons/Pleer/AudioSvg";
export default {
  components: {AudioSvg},
  props:{
    hasAudio: Boolean,
    volume: Number
  },
  name: "IntefaceFiniteAudio",
  methods: {
    changeAudio(size) {
      document.documentElement.style.setProperty('--range', (size * 100) + '%')
      this.$emit('changeVolume',size)
    },
    changeAudioSize(e) {
      const val = Number.parseFloat(e.target.value)
      if (val > 0) {
        this.changeAudio(val)
      } else {
        this.changeAudio(0)
      }

    }
  },
  watch: {
    getVolumeSize(size) {
      console.log(size)
      document.documentElement.style.setProperty('--range', (size * 100) + '%')
    }
  },
  mounted() {
    document.documentElement.style.setProperty('--range', (this.getVolumeSize * 100) + '%')
  }
}
</script>

<style scoped lang="scss">
:root {
  --range: 0%
}

.audio {
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    vertical-align: middle;
    position: relative;
    top: 2px;
    cursor: pointer;
  }


  &__input {
    margin: 0 4px;
    position: relative;
    top: -2px;

    input {
      display: inline-block;
      appearance: none;
      position: relative;
      width: 50px;
      cursor: pointer;
      height: 3px;
      //background: #E2E2E2;
      //opacity: 0.1;
      background-color: transparent;
      border-radius: 2.45px;

      &::before {
        position: absolute;
        content: '';
        width: var(--range);
        border-radius: 2.45px;
        height: 3px;
        background: #E2E2E2;
      }

      &::-webkit-slider-runnable-track {
        height: 3px;
        border-radius: 2.45px;
        background-color: rgba(226,226,226,.1);
      }

      &::-webkit-slider-thumb {
        height: 3px;
        width: 3px;
        appearance: none;
        border-radius: 2.45px;
        //background-color: #E2E2E2;


        background: rgb(226,226,226);
        width: 10px;
        height: 10px;
        border-radius: 5px;
        margin-top: -3.5px;
        z-index: 2;
      }
    }

    label {
      background-color: #7e8bc6;
    }
  }
}
</style>
