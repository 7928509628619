<template>
  <div class="video"
       ref="wrapper"
       :class="isShowControls && !isFullScreen ? 'video_bg' : ''"
       @click="clickToVideo"
       @mousemove="onMouseMove"
       @mouseover="showControls"
       @mouseleave="hideControls"
  >
    <div class="video__video" ref="videoWrap" :style="getWrapperStyles">
      <div :style="getVideoStyles" class="video__container" ref="videoContainer">
        <video ref="video"
               class="video__item"
               :class="[videoSet.current.z > 1 ? 'video__item_grab' : '',
                   countTap === 2 ? 'video__item_translate' : '',
                   isFullScreen ? 'video__item_fullscreen' : '']"
               controls="controls"
               playsinline autoplay muted></video>
        <div class="video__filter-container" :style="getFilterStyles">
          <filter-object
              v-if="mut.height != null"
              :height="mut.height"
              :width="mut.width"
              :left="mut.left"
              :top="mut.top"
              :is-round="mut.isRound"/>
          <p class="watermark">id{{$store.getters.PROFILE._id}}</p>
        </div>
      </div>
      <video-finite-interface
          class="video__interface"
          v-if="isFullScreen"
          :video="video"
          :hasAudio="hasAudio"
          :stop="stop"
          :zoom="videoSet.current.z"
          :volume="volume"
          :current-time="currentTime"
          :time-end="duration"
          :speed="speed"
          :is-visible="isVisible"
          @current="onChangeCurrentTime"
          @changeSpeed="onChangeSpeed"
          @changePlay="changePlay"
          @changeZoom="changeZoom"
          @exitScreen="exitScreen"
          @changeVolume="changeVolume"
          @mouseenter.native="disablePan"
          @mouseleave.native="enablePan"
      ></video-finite-interface>
    </div>
    <div class="video__loader" v-if="isLoading">
      <video-loader></video-loader>
    </div>
    <div class="video__info" v-if="isShowControls">
      <div v-if="hasAudio !== null" class="video__audio">
        <div v-if="hasAudio" class="video__sound">
          <a class="icon icon-mute-1 volume-off--image" @click="changeVolume(1)" v-if="isMuted"
             :class="{'show' : isShowControls}"></a>
          <a class="icon icon-loud volume-on--image" @click="changeVolume(0)" v-else
             :class="{'show' : isShowControls}"></a>
        </div>
        <div v-else class="video__no-sound">
          <div class="no-audio">Без звука</div>
        </div>
      </div>
      <div class="video__fullscreen" @click="goToFullSize">
        <svg width="21px" height="16px" viewBox="0 0 21 16" version="1.1" xmlns="http://www.w3.org/2000/svg"
             class="video__svg">
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Group-6" fill="#FFFFFF" fill-rule="nonzero">
              <path d="M20.2222222,-4.54747351e-13 C20.6159808,-4.54747351e-13 20.941398,0.29260403 20.9928998,0.67223794 L21,0.77777778 L21,6.0174323 L19.4444444,6.01669296 L19.4444444,1.55555556 L14,1.55555556 L14,-4.54747351e-13 L20.2222222,-4.54747351e-13 Z" id="Path-4"></path>
              <path d="M6.22222222,-4.54303262e-13 C6.61598079,-4.54303262e-13 6.94139803,0.29260403 6.99289981,0.67223794 L7,0.77777778 L7,5.99530543 L5.44444444,5.99799182 L5.44444444,1.55555556 L0,1.55555556 L0,-4.54303262e-13 L6.22222222,-4.54303262e-13 Z" id="Path-4" transform="translate(3.500000, 2.998996) scale(-1, 1) translate(-3.500000, -2.998996) "></path>
              <path d="M20.2222222,9.99709028 C20.6159808,9.99709028 20.941398,10.2896943 20.9928998,10.6693282 L21,10.7748681 L21,16 L19.4444444,15.9930379 L19.4444444,11.5526459 L14,11.5526459 L14,9.99709028 L20.2222222,9.99709028 Z" id="Path-4" transform="translate(17.500000, 12.998545) scale(-1, 1) rotate(-180.000000) translate(-17.500000, -12.998545) "></path>
              <path d="M6.22222222,9.99857981 C6.61598079,9.99857981 6.94139803,10.2911838 6.99289981,10.6708177 L7,10.7763576 L7,16 L5.44444444,15.9924914 L5.44444444,11.5541354 L0,11.5541354 L0,9.99857981 L6.22222222,9.99857981 Z" id="Path-4" transform="translate(3.500000, 12.999290) rotate(180.000000) translate(-3.500000, -12.999290) "></path>
            </g>
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import * as Hls from 'hls.js'
import VideoInterface from "./VideoInterface";
import VideoLoader from "@/components/loader/VideoLoader";
import FilterObject from "@/components/FilterObject";
import ResizeObserver from 'resize-observer-polyfill';
import * as panzoom from "panzoom"
import VideoFiniteInterface from "./VideoFiniteInterface";



export default {
  name: "VideoFiniteItem",
  components: {VideoFiniteInterface, FilterObject, VideoLoader, VideoInterface},
  props: {
    video: Object,
    frame: Object,
    type: String,
    schid: Number
  },
  data() {
    return {
      timeout: null,
      isVisible: true,
      speed: 1,
      duration: 0,
      currentTime: 0,
      volume: 0,
      zoom: 1,
      mut: {},
      isLoading: true,
      clickToView: false,
      hasAudio: null,
      isMuted: true,
      isShowControls: false,
      stop: false,
      showError: false,
      isFullScreen: false,
      desktopZoomInstance: null,
      isMobile: null,
      countTap: 0,
      position: {
        top: 0,
        bottom: 0
      },
      isIos: false,
      videoSet: {
        pinchStart: {
          x: 0,
          y: 0,
        },
        originalSize: {
          width: 0,
          height: 0
        },
        current: {
          x: 0,
          y: 0,
          z: 1,
          zooming: false,
          width: 0,
          height: 0
        },
        last: {
          x: 0,
          y: 0,
          z: 1
        },
        type: undefined,
        delta: {x: undefined, y: undefined}
      },
      filterContainerStyle:{
        width: 0,
        height: 0
      },
    }
  },
  computed: {
    getScale() {
      return this.videoSet.current.z
    },
    getVideoStyles() {
      if (this.isFullScreen) {
        if (this.videoSet.current.z !== 1 && this.isMobile) {
          return {
            'transform': 'translate3d(' + this.videoSet.current.x + 'px,' + this.videoSet.current.y + 'px, 0) scale(' + this.videoSet.current.z + ')'
          }
        }

      }
      return {'transform': 'translate3d(0,0,0) scale(1)'}
    },
    getWrapperStyles() {
      if (this.isIos && this.isMobile && this.isFullScreen) {
        return {
          position: 'fixed',
          top: '0',
          bottom: 0,
          left: 0,
          right: 0,
          width: '100%',
          height: '100%',
          'z-index': 100,
          background: '#000'
        }
      }
      return {}
    },
    getShow() {
      return true
    },
    getVolume() {
      return this.volume
    },

    getFilterStyles(){
      return this.filterContainerStyle
    }
  },
  methods: {
    disablePan(){
      if (!this.isMobile){
        this.desktopZoomInstance.pause();
      }
    },
    enablePan(){
      if (!this.isMobile){
        this.desktopZoomInstance.resume();
      }
    },
    changeTap() {
      if (this.$windowWidth < 993 && this.isFullScreen && this.videoSet.current.z > 1) {
        this.countTap++
      }
    },


    changeVolume(size) {
      this.volume = size
      this.toggleMute(size)
      this.$refs.video.volume = size
    },
    computedFilterObject(){
      let video = this.$refs.video
      if (video == null) return {}
      this.filterContainerStyle = {...{
          height : video.clientHeight + 'px',
          width: video.clientWidth + 'px',
          position: 'absolute',
        }}


      if (this.video.blurPreference == null) return {}


      const xcoef = video.clientWidth/this.video.blurPreference.cw
      const ycoef = video.clientHeight/this.video.blurPreference.ch
      const coef = (xcoef+ycoef)/2
      const obj ={
        top: `${this.video.blurPreference.y*ycoef}px`,
        left: `${this.video.blurPreference.x*xcoef}px`,
        width: `${this.video.blurPreference.w*xcoef}px`,
        height: `${this.video.blurPreference.h*ycoef}px`,
        isRound: this.video.blurPreference.isRound
      }
      return obj;
    },
    loadShow() {
      const video = this.$refs.wrapper.parentNode
      this.position.top = video.offsetTop
      this.position.bottom = this.position.top + video.clientHeight
    },
    generateVideo() {
      const video = this.$refs.video
      this.hlsPlayer = new Hls({
        lowLatencyMode: true,
        backBufferLength: 90
      })
      video.volume = 0
      if (Hls.isSupported()) {
        this.hlsPlayer.on('hlsFragChanged', (e, data) => {
          const range = {
            startOffset: 0,
            endOffset: data.frag.startDTS
          }
          this.hlsPlayer.trigger('hlsBufferFlushing', range)
        })
      }
    },
    playVideo() {
      try{
        const video = this.$refs.video
        this.isLoading = true
        video.muted=true
        this.isMuted = true

        this.$refs.video.removeAttribute("controls");


        this.videoSet.originalSize = {
          width: this.$refs.video.clientWidth,
          height: this.$refs.video.clientHeight
        }

        if (Hls.isSupported()) {
          this.hlsPlayer.loadSource(this.video.uri)
          this.hlsPlayer.attachMedia(video)
        } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
          video.src = this.video.uri
        }

        video.addEventListener('waiting', () => {
          this.isLoading = true
        }, false)

        video.addEventListener('playing', () => {
          this.duration = video.duration
          if (typeof video.webkitAudioDecodedByteCount !== 'undefined') {
            if (video.webkitAudioDecodedByteCount > 0) this.hasAudio = true
            else this.hasAudio = false
          } else if (typeof video.mozHasAudio !== 'undefined') {
            if (video.mozHasAudio) this.hasAudio = true
            else this.hasAudio = false
          } else if (typeof video.audioTracks !== 'undefined') {
            if (video.audioTracks && video.audioTracks.length) this.hasAudio = true
            else this.hasAudio = false
          } else {
            this.hasAudio = false
          }
          //this.isLoading = false
        }, false)

        video.addEventListener('loadstart', (e) => {
          if (e.target.paused) {
            //this.isLoading = false
          } else {
            this.isLoading = true
          }
        }, false)

        video.addEventListener('error', () => {
          this.isLoading = false
        }, false)

        video.addEventListener('timeupdate', () => {
          this.isLoading = false
        }, false)

        video.addEventListener('webkitendfullscreen', () => {
          const document = this.$refs.videoWrap

          if (document.fullScreenElement || document.webkitIsFullScreen == true || document.mozFullScreen || document.msFullscreenElement ){
            console.log("yep")
            this.exitScreen()
          }
          setTimeout(() => {
            video.play()
          }, 1500)
        }, false)


        video.addEventListener('timeupdate',() => {
          this.currentTime = video.currentTime
        })
      } catch (e){
        console.error("wtf",e)
      }

    },
    toggleMute(size) {
      const isMuted = !(size > 0)
      this.isMuted = isMuted
      this.$refs.video.muted = isMuted
      this.$emit("changeMute",this.isMuted)
    },
    mute(){
      this.isMuted = true
      this.$refs.video.muted = true
    },
    async goToFullSize() {
      await this.$store.dispatch("changeFullScreen",{isFullscreen: 1,type: this.type,scheduleInfo: this.schid })
      this.$emit("changeFullscreen",!this.isFullScreen)
      const e = this.$refs.videoWrap
      if (!this.isIos || (this.isIos && !this.isMobile)) {
        if (e.requestFullscreen) {
          await e.requestFullscreen()
        } else if (e.mozRequestFullScreen) {
          await e.mozRequestFullScreen()
        } else if (e.webkitRequestFullScreen) {
          await e.webkitRequestFullScreen()
        }
      }
      if(!this.isMobile){
        this.timeout = setTimeout(() => this.isVisible = false,3000)
      }
      this.isFullScreen = true
    },

    onTapVideo(){
      return (direction,event) => {
        if (this.isFullScreen && this.isMobile){
          this.isVisible = !this.isVisible
        }
      }
    },
    showControls(el) {
      if (this.$windowWidth > 992) {
        // const classes = el.target.parentNode.classList
        // let classInVideo = false
        // for (let item of classes) {
        //   console.log(item)
        //   if (item.indexOf('video') !== -1) {
        //     classInVideo = true
        //   }
        // }
        // console.log(el,classInVideo)
        // if (classInVideo) {
        //   this.isShowControls = true
        // } else {
        //   this.isShowControls = false
        // }
        this.isShowControls = true

      }
    },
    hideControls(el) {
      if (el.type === 'mouseover' || el.type === 'mouseleave') {
        this.isShowControls = false
      }
    },
    clickToVideo(e) {
      const el = e.target.classList[0]
      if (this.isFullScreen){
        if (this.isMobile && (el === 'video__item' || el === 'video')){
          this.isVisible = !this.isVisible
        } else {
          this.isVisible = !(el === 'video__item' || el === 'video')
        }
      } else if (!this.isFullScreen) {
        if (this.$windowWidth < 993) {
          this.isShowControls = !(this.isShowControls && (el === 'video__item' || el === 'video'));
        }
      }
    },
    onMouseMove(e){
      if (!this.isMobile){
        clearTimeout(this.timeout)
        this.isVisible = true
        const el = e.target.classList[0]
        if (el === 'video__item' || el === 'video'){
          this.timeout = setTimeout(() => this.isVisible = false,3000)
        }
      }
    },
    changeFullScreen() {
      if (!(document.fullscreenElement || document.webkitCurrentFullScreenElement)) {
        this.exitScreen()
        this.$emit("changeFullscreen",this.isFullScreen)
      }
    },
    changePlay(type) {
      return this.stop = type
    },
    changeZoom(e) {
      var x = window.innerWidth / 2;
      var y = window.innerHeight / 2;
      this.videoSet.current.z = 1
      this.desktopZoomInstance.smoothZoom(x, y, 0);
    },
    async exitScreen() {
      await this.$store.dispatch("changeFullScreen",{isFullscreen: 0,type: this.type })
      if (!this.isIos || (this.isIos && !this.isMobile)){
        if (document.exitFullscreen) {
          await document.exitFullscreen()
        } else if (document.webkitExitFullscreen) {
          await document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          await document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          await document.msExitFullscreen();
        }
      }
      this.isFullScreen = false

      this.$refs.videoContainer.style.transform = null
      this.desktopZoomInstance.zoomTo(0,0,0)
      this.$emit("changeFullscreen",this.isFullScreen)

    },
    stopVideo(){
      if (this.hlsPlayer != null){
        console.log(this.hlsPlayer)
        this.hlsPlayer.detachMedia();
      }
    },

    fullStop(){
      if (this.hlsPlayer != null){
        this.hlsPlayer.destroy()
      }
    },

    onChangeCurrentTime(time){
      this.$refs.video.currentTime = time;
    },

    onChangeSpeed(speed){
      this.$refs.video.playbackRate = speed
      this.speed = speed
    }

  },
  mounted() {
    this.isMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
    this.isIos = this.$refs.video.canPlayType('application/vnd.apple.mpegurl')
    this.isLoading = true

    this.generateVideo()
    this.playVideo()

    const ro = new ResizeObserver(entries => {
      if (this.videoSet.current.z === 1){
        this.mut = {...this.computedFilterObject()}
      }
    });

    ro.observe(this.$refs.video);
  },
  watch: {
    getScale() {
      if (this.videoSet.current.z <= 1) {
        this.videoSet.current.z = 1
        this.videoSet = {
          pinchStart: {
            x: 0,
            y: 0,
          },
          originalSize: {
            width: this.$refs.video.clientWidth,
            height: this.$refs.video.clientHeight
          },
          current: {
            x: 0,
            y: 0,
            z: 1,
            zooming: false,
            width: this.$refs.video.clientWidth,
            height: this.$refs.video.clientHeight
          },
          last: {
            x: 0,
            y: 0,
            z: 1
          },
          type: undefined,
          delta: {x: undefined, y: undefined}
        }

      }
    },
    countTap() {
      setTimeout(() => {
        this.countTap = 0
      }, 500)
      if (this.countTap === 2) {
        this.videoSet.current.z = 1
      }
    },
    isFullScreen() {
      if (this.isFullScreen) {
        document.addEventListener('fullscreenchange', this.changeFullScreen)
        document.addEventListener("webkitfullscreenchange", this.changeFullScreen);

        if (this.isMobile){
          console.log(this.isMobile)
          this.desktopZoomInstance = panzoom(this.$refs.videoContainer, {
            maxZoom: 7,
            minZoom: 1,
            bounds: true,
            boundsPadding: 1,
            zoomDoubleClickSpeed: 0,
            onTouch: (e) => {
              return e.target.classList[0] === 'video__interface'
            }
          },)
        } else {
          this.desktopZoomInstance = panzoom(this.$refs.videoContainer, {
            maxZoom: 7,
            minZoom: 1,
            bounds: true,
            boundsPadding: 1,
            zoomDoubleClickSpeed: 0
          },)
        }


        this.desktopZoomInstance.on('zoom', () => {
          this.videoSet.current.z = this.desktopZoomInstance.getTransform().scale
          if (this.desktopZoomInstance.getTransform().scale <= 1){
            this.$refs.video.style.transform = null
          }
        });
      } else {
        document.removeEventListener('fullscreenchange', this.changeFullScreen)
        this.stop = false
        this.videoSet.current.z = 1

        this.desktopZoomInstance.dispose()

      }
    },

    video() {
      this.playVideo()

    },
    getShow() {
      this.playVideo()

    },
    getVolume() {
      const video = this.$refs.video
      if (this.volume === 0) {
        video.volume = 0
        this.isMuted = true
      } else {
        video.volume = this.volume
        this.isMuted = false
      }
    },
    stop(val) {
      const video = this.$refs.video
      if (val) {
        video.pause()
      } else {
        video.play()
      }
    }
  },
  beforeDestroy() {
    if (this.isFullScreen){
      this.exitScreen()
    }

    if (this.hlsPlayer != null && !this.isIos) {
      this.hlsPlayer.detachMedia(this.$refs.video)
      this.hlsPlayer.destroy()
    } else {
      this.$refs.video.pause()
      this.$refs.video.removeAttribute('src') // empty source
      this.$refs.video.load()
    }
    window.removeEventListener('orientationchange', this.loadShow)
  }
}
</script>

<style scoped lang="scss">
.video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #000;
  bottom: 0;
  &_bg {
    &::before {
      content: '';
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.224365) 50%, rgba(0, 0, 0, 0.0001) 100%);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 25%;
      z-index: 4;
      transition: all .25s;
      opacity: 1;
    }
    &::after {
      content: '';
      position: absolute;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
      bottom: 0;
      height: 25%;
      z-index: 4;
      transition: all .25s;
      opacity: 1;
      left: 0;
      right: 0;
    }
  }

  &__container{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    touch-action: pan-x pan-y;
    background-color: black;

  }

  video {
    width: 100%;
    height: auto;
    z-index: 2;
  }

  &__loader {
    z-index: 4;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
  }

  &__title {
    font-size: 14px;
    font-family: Roboto, "sans-serif";
    font-weight: 100;
    letter-spacing: 0;
    line-height: 16px;
    position: absolute;
    left: 18px;
    top: 19px;
    color: white;
    z-index: 5;
  }

  &__interface{

  }

  &__item {
    //transition: all .25s;
    &_grab {
      cursor: grab;
    }

    &_translate {
      transition: all .25s;
    }

    &_fullscreen{
      width: 100%;
      height: auto !important;
    }
  }

  &__text {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 4;
    color: #fff;
    transform: translateY(-50%) translateX(-50%);
    max-width: 300px;
    width: 100%;

    &::before {
      content: '';
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.224365) 14.52%, rgba(0, 0, 0, 0.0001) 26.35%, rgba(0, 0, 0, 0.0001) 75.63%, rgba(0, 0, 0, 0.5) 100%);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 4;
      transition: all .25s;
      opacity: 0;
    }

    span {
      max-width: 320px;
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__info {
    opacity: 1;
    z-index: 5;
    transition: all .5s;

    &:hover {
      opacity: 1;
    }
  }

  &__archive {
    width: 24px;
    height: 15px;
    position: absolute;
    top: 16px;
    right: 22px;
    cursor: pointer;

    &.right {
      right: 60px;
    }

    z-index: 5;
  }

  &__fullscreen {
    width: 20px;
    z-index: 5;
    height: 18px;
    position: absolute;
    bottom: 18px;
    right: 30px;
    cursor: pointer;
  }

  &__share {
    position: absolute;
    right: 22px;
    top: 16px;
    width: 19px;
    height: 17px;
    z-index: 5;

    &:hover {
      filter: brightness(80%);
    }
  }
}

.volume-off--image {
  position: absolute;
  width: 20px;
  height: 20px;
  font-size: 20px;
  bottom: 18px;
  left: 20px;
  z-index: 5;
  cursor: pointer;
}

.volume-on--image {
  position: absolute;
  z-index: 5;
  width: 20px;
  height: 20px;
  font-size: 20px;
  bottom: 18px;
  left: 20px;
  cursor: pointer;
}

.no-audio {
  position: absolute;
  bottom: 24px;
  left: 18px;
  z-index: 5;
  font-size: 11px;
  font-weight: 100;
  letter-spacing: 0.34px;
  font-family: Roboto, sans-serif;
  color: #fff;
}

.watermark{
  font-family: Roboto, "sans-serif";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1px;
  position: absolute;
  left: 17px;
  bottom: 17px;
  z-index: 3;

  color: rgba(255,255,255,.1);
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
}
</style>
