<template>
  <svg width="18px" height="11px" viewBox="0 0 18 11" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>2x 2</title>
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="инструменты/-Скорость-воспроизведения-/-окошечко" transform="translate(-11.000000, -20.000000)" fill="#E2E2E2" fill-rule="nonzero">
        <g id="2x-2" transform="translate(11.000000, 20.000000)">
          <path d="M6.88378906,10.0898438 L6.88378906,8.76367188 L2.26269531,8.76367188 L4.69628906,6.15917969 C5.3343099,5.46647135 5.79345703,4.84781901 6.07373047,4.30322266 C6.35400391,3.7586263 6.49414062,3.23795573 6.49414062,2.74121094 C6.49414062,1.8844401 6.21500651,1.21337891 5.65673828,0.728027344 C5.09847005,0.242675781 4.33626302,0 3.37011719,0 C2.70019531,0 2.10888672,0.134440104 1.59619141,0.403320312 C1.08349609,0.672200521 0.689290365,1.04931641 0.413574219,1.53466797 C0.137858073,2.02001953 0,2.56575521 0,3.171875 L0,3.171875 L1.66113281,3.171875 C1.66113281,2.60221354 1.80924479,2.15332031 2.10546875,1.82519531 C2.40169271,1.49707031 2.8186849,1.33300781 3.35644531,1.33300781 C3.80761719,1.33300781 4.16650391,1.47884115 4.43310547,1.77050781 C4.69970703,2.06217448 4.83300781,2.44498698 4.83300781,2.91894531 C4.83300781,3.27897135 4.73160807,3.63785807 4.52880859,3.99560547 C4.32600911,4.35335286 3.98535156,4.79882812 3.50683594,5.33203125 L3.50683594,5.33203125 L0.19140625,8.94824219 L0.19140625,10.0898438 L6.88378906,10.0898438 Z M12.4609375,10.0898438 L13.9306641,7.56054688 L15.4072266,10.0898438 L17.2392578,10.0898438 L14.9013672,6.33691406 L17.1640625,2.69335938 L15.3183594,2.69335938 L13.9101562,5.12695312 L12.5292969,2.69335938 L10.6972656,2.69335938 L12.953125,6.33691406 L10.6152344,10.0898438 L12.4609375,10.0898438 Z" id="2x"></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "TwoXSpeedSvg"
}
</script>

<style scoped>
svg:hover{
  opacity: .8;
}

svg{
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

}
</style>
