<template>
  <svg
      ref="svg"
      v-touch:start="onTouchStart"
      v-touch:end="onTouchStop"
      width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>инструменты/ Плей</title>
    <g id="инструменты/-Плей" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path d="M12.0015758,4.20123803 L21.1001035,17.4300324 C21.4130758,17.8850783 21.2979025,18.5076801 20.8428567,18.8206525 C20.6761265,18.9353265 20.4785289,18.9967191 20.2761701,18.9967191 L2.07911482,18.9967191 C1.52683007,18.9967191 1.07911482,18.5490038 1.07911482,17.9967191 C1.07911482,17.7943602 1.14050735,17.5967627 1.25518141,17.4300324 L10.353709,4.20123803 C10.6666814,3.74619218 11.2892832,3.63101887 11.7443291,3.94399125 C11.8450857,4.01328987 11.9322772,4.10048139 12.0015758,4.20123803 Z" id="Triangle" fill="#E2E2E2" transform="translate(11.177642, 11.000000) rotate(-270.000000) translate(-11.177642, -11.000000) "></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: "PlaySvg",
  methods:{
    onTouchStart(){
      this.$refs.svg.style.opacity = .8
    },
    onTouchStop(){
      this.$refs.svg.style.opacity = 1
    }
  }
}
</script>

<style scoped lang="scss">
  svg:hover{
    opacity: .8;
  }

  svg{
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }
</style>
