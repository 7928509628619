<template>
  <svg
      ref="svg"
      v-touch:start="onTouchStart"
      v-touch:end="onTouchStop"
      width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>инструменты/ Пауза</title>
    <g id="инструменты/-Пауза" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path d="M7,1 C7.55228475,1 8,1.44771525 8,2 L8,20 C8,20.5522847 7.55228475,21 7,21 L3,21 C2.44771525,21 2,20.5522847 2,20 L2,2 C2,1.44771525 2.44771525,1 3,1 L7,1 Z M19,1 C19.5522847,1 20,1.44771525 20,2 L20,20 C20,20.5522847 19.5522847,21 19,21 L15,21 C14.4477153,21 14,20.5522847 14,20 L14,2 C14,1.44771525 14.4477153,1 15,1 L19,1 Z" id="Combined-Shape" fill="#E2E2E2"></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: "StopSvg",
  methods:{
    onTouchStart(){
      this.$refs.svg.style.opacity = .8
    },
    onTouchStop(){
      this.$refs.svg.style.opacity = 1
    }
  }
}
</script>

<style scoped lang="scss">
svg:hover{
  opacity: .8;
}

svg{
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
</style>
