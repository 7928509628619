<template>
  <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>инструменты деск/ звук / on</title>
    <g id="инструменты-деск/-звук-/-on" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path d="M9.16233468,13.6387682 C9.16233468,13.9217766 8.99881489,14.1788843 8.7429159,14.2988909 C8.6439752,14.3456848 8.53795498,14.3683048 8.43262544,14.3683048 C8.26582488,14.3683048 8.10057837,14.3109779 7.9671034,14.2002955 L3.97873988,10.8953653 L1.85453658,10.8953653 C1.45169425,10.8957107 1.125,10.5688437 1.125,10.1660014 L1.125,7.25942409 C1.125,6.85640909 1.45169425,6.52971484 1.85453658,6.52971484 L3.97891255,6.52971484 L7.96727607,3.22478464 C8.18518735,3.04417038 8.48736226,3.00566466 8.74308858,3.12653463 C8.99881489,3.24654124 9.16250736,3.50382159 9.16250736,3.78665731 L9.16233468,13.6387682 L9.16233468,13.6387682 Z" id="Path" fill="#E2E2E2" fill-rule="nonzero"></path>
      <path class="sm-line" d="M1.50335835,2.45884622 C1.79835881,2.1865381 2.24556912,2.18257204 2.54465099,2.43493451 L2.61616164,2.50335835 L12.0661616,12.7408584 C12.3611621,13.0604422 12.3412333,13.5586612 12.0216495,13.8536616 C11.7266491,14.1259698 11.2794387,14.1299358 10.9803569,13.8775734 L10.9088462,13.8091495 L1.45884622,3.57164951 C1.16384576,3.25206568 1.18377452,2.75384668 1.50335835,2.45884622 Z" id="Path-3" stroke="#FFFFFF" stroke-width="0.5" fill="#E2E2E2" fill-rule="nonzero"></path>
      <path class="sm-audio1" d="M11.9659999,12.4987917 C11.9482147,12.5000004 11.9311202,12.5006911 11.9135078,12.5006911 C11.7208065,12.5006911 11.5350121,12.4245431 11.3977384,12.2870966 L11.300179,12.189192 C11.04428,11.933811 11.0142352,11.529242 11.2297291,11.2388087 C11.7760614,10.5021926 12.0644226,9.62899342 12.0644226,8.71297176 C12.0644226,7.72770887 11.7370376,6.80357165 11.1174927,6.04036415 C10.8816236,5.75027623 10.9033802,5.32895807 11.1677401,5.06477086 L11.2651267,4.96721153 C11.4108614,4.82147689 11.6061527,4.74204805 11.8175025,4.75465306 C12.0233268,4.76501334 12.21551,4.86188199 12.3460496,5.02143034 C13.205435,6.07299904 13.6593881,7.34973122 13.6593881,8.71314443 C13.6593881,9.98296975 13.2579271,11.1916694 12.4981731,12.2080131 C12.3712596,12.3774038 12.177177,12.4837693 11.9659999,12.4987917 Z" id="Path" fill="#E2E2E2" fill-rule="nonzero"></path>
      <path class="sm-audio2" d="M14.9822235,14.7533619 C14.8503026,14.9092842 14.6595007,15.0028721 14.4552305,15.0115057 C14.4452155,15.011851 14.4350279,15.0121963 14.4246676,15.0121963 C14.2314484,15.0121963 14.0459993,14.9358756 13.9087256,14.7986018 L13.8128929,14.7027692 C13.5450796,14.4351286 13.5269491,14.0070762 13.7704158,13.7173337 C14.9469986,12.3180048 15.5952069,10.540871 15.5952069,8.71297176 C15.5952069,6.8116872 14.9012406,4.98137058 13.6416029,3.5592491 C13.386222,3.27054256 13.3991723,2.8335113 13.6709571,2.56069052 L13.766617,2.4648579 C13.9085529,2.32223134 14.0929659,2.24366587 14.3044884,2.25040005 C14.5053052,2.25609821 14.6952437,2.34485129 14.8287187,2.49507539 C16.3513349,4.20935685 17.1899999,6.41782379 17.1899999,8.71297176 C17.1903451,10.921266 16.4062444,13.0665352 14.9822235,14.7533619 Z" id="Path" fill="#E2E2E2" fill-rule="nonzero"></path>
    </g>
  </svg>
</template>

<script>
import anime from 'animejs/lib/anime.es.js';
export default {
  name: "AudioSvg",
  props:{
    isAudio: Boolean
  },
  data(){
    return {
      tl: null
    }
  },
  watch:{
    // async isAudio(val){
    //   if (this.tl != null){
    //     this.tl.pause()
    //   }
    //    this.tl = anime.timeline({
    //     easing: 'easeOutExpo',
    //     duration: 300
    //   });
    //   if(val){
    //     this.tl
    //         .add({
    //           targets: '.sm-line',
    //           scale: 0
    //         })
    //         .add({
    //           targets: '.sm-audio1',
    //           opacity: 1
    //         }, ) // relative offset
    //         .add({
    //           targets: '.sm-audio2',
    //           opacity: 1
    //         }, ); // absolute offset
    //   } else {
    //     this.tl
    //         .add({
    //           targets: '.sm-audio2',
    //           opacity: 0
    //         }, )
    //         .add({
    //           targets: '.sm-audio1',
    //           opacity: 0
    //         }, ) // relative offset
    //         .add({
    //           targets: '.sm-line',
    //           scale: 1
    //         }); // absolute offset
    //   }


    async isAudio(val){
      if(val){

        anime({
          targets: '.sm-line',
          scale: 0,
          easing: 'easeOutExpo',
          delay: 0,
          duration: 250
        });

        anime({
          targets: '.sm-audio1',
          opacity: 1,
          easing: 'easeOutExpo',
          delay: 0,
          duration: 1000
        });

        anime({
          targets: '.sm-audio2',
          opacity: 1,
          easing: 'easeOutExpo',
          delay: 0,
          duration: 1000
        });
      } else {
        anime({
          targets: '.sm-audio2',
          opacity: 0,
          easing: 'easeOutExpo',
          delay: 0,
          duration: 1000
        });

        anime({
          targets: '.sm-audio1',
          opacity: 0,
          easing: 'easeOutExpo',
          delay: 0,
          duration: 1000
        });

        anime({
          targets: '.sm-line',
          scale: 1,
          easing: 'easeOutExpo',
          delay: 0,
          duration: 250
        });
      }
    }
  }
}
</script>

<style scoped>
.sm-line{
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}


svg{
  -webkit-tap-highlight-color: transparent;

}


.sm-audio1{
  opacity: 0;
}

.sm-audio2{
  opacity: 0;
}
</style>
