import { render, staticRenderFns } from "./AudioBigSvg.vue?vue&type=template&id=49db6d63&scoped=true&"
import script from "./AudioBigSvg.vue?vue&type=script&lang=js&"
export * from "./AudioBigSvg.vue?vue&type=script&lang=js&"
import style0 from "./AudioBigSvg.vue?vue&type=style&index=0&id=49db6d63&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49db6d63",
  null
  
)

export default component.exports