<template>
  <div class="interface__wrapper interface__wrapper_mobile">
    <div class="interface-mobile__top">
      <div class="interface-mobile__close" :class="{'hide' : !isVisible}">
        <ExitSvg @click.native="$emit('exitScreen')"/>
      </div>
      <div class="interface-mobile__zoom" :class="{'hide' : !isVisible}">
        <ZoomSvg
            @click.native="$emit('changeZoom', 1)"
            :class="zoom === 1 ? 'interface-mobile__zoom_hide' : ''"
        />
      </div>
    </div>
    <div class="interface-mobile__audio" :class="{'_disabled' : !hasAudio,'hide' : !isVisible}">
      <div class="interface-mobile__wrap">
        <AudioBigSvg
            :is-audio="volume !== 0"
            @click.native="toggleVolume"
            />
      </div>
    </div>
    <div class="interface__wrapper_mobile_vertical" :class="{'hide' : !isVisible}">
      <div class="interface-mobile-vertical__top">
        <PreviousSvg
            class="interface-mobile-vertical__back"
            @click.native="modifyPlayline(-15)"
        />
        <StopSvg
            class="interface-mobile-vertical__play"
            v-if="!stop"
            @click.native="$emit('changePlay', true)"
        />
        <PlaySvg
            class="interface-mobile-vertical__play"
            v-if="stop"
            @click.native="$emit('changePlay', false)"
        />
        <NextSvg
            class="interface-mobile-vertical__next"
            @click.native="modifyPlayline(15)"
        />

        <div class="interface-mobile-vertical__speed speed">
          <div class="speed__select"
               :class="{'speed__select_opened' : isSpeedSelectorOpened}">
            <div class="speed__text"
                 id="speed1"
                 v-touch:start="onTouchStart('speed1')"
                 v-touch:end="onTouchStop('speed1')"
                 @click="onChangeSpeed(2)"><TwoXSpeedSvg/></div>
            <div class="speed__text"
                 id="speed2"
                 v-touch:start="onTouchStart('speed2')"
                 v-touch:end="onTouchStop('speed2')"
                 @click="onChangeSpeed(1.5)"><PoltoraXSpeedSvg/></div>
            <div class="speed__text"
                 id="speed3"
                 v-touch:start="onTouchStart('speed3')"
                 v-touch:end="onTouchStop('speed3')"
                 @click="onChangeSpeed(1)"><OneXSpeedSvg/></div>
          </div>
          <p class="interface-mobile-vertical__speed-label" @click="toggleSpeedSelect">{{speed}}x</p>
        </div>
      </div>
      <div class="interface-mobile-vertical__playline">
        <input
            ref="playline"
            type="range"
            min="0"
            max="100"
            value="0"
            step="0.01"
            id="range"
            @input="onChangeCurrent"
            v-touch:start="onMouseDown"
            v-touch:end="onMouseUp"
        >
        <label for="range"></label>
      </div>
      <div class="interface-mobile-vertical__bottom">
        <p class="interface-mobile-vertical__time">{{current}}</p>
        <p class="interface-mobile-vertical__time">{{duration}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import PreviousSvg from "../../assets/vue-icons/Pleer/PreviousSvg";
import StopSvg from "../../assets/vue-icons/Pleer/StopSvg";
import PlaySvg from "../../assets/vue-icons/Pleer/PlaySvg";
import NextSvg from "../../assets/vue-icons/Pleer/NextSvg";
import TwoXSpeedSvg from "../../assets/vue-icons/Pleer/TwoXSpeedSvg";
import PoltoraXSpeedSvg from "../../assets/vue-icons/Pleer/PoltoraXSpeedSvg";
import OneXSpeedSvg from "../../assets/vue-icons/Pleer/OneXSpeedSvg";
import ExitSvg from "../../assets/vue-icons/Pleer/ExitSvg";
import ZoomSvg from "../../assets/vue-icons/Pleer/ZoomSvg";
import AudioBigSvg from "../../assets/vue-icons/Pleer/AudioBigSvg";
export default {
  name: "VideoFiniteInterfaceMobilePortait",
  components: {
    AudioBigSvg,
    ZoomSvg, ExitSvg, OneXSpeedSvg, PoltoraXSpeedSvg, TwoXSpeedSvg, NextSvg, PlaySvg, StopSvg, PreviousSvg},
  props:{
    video: Object,
    stop: Boolean,
    zoom: Number,
    volume: Number,
    hasAudio: Boolean,
    timeEnd: Number,
    currentTime: Number,
    speed: Number,
    isVisible: Boolean
  },
  data(){
    return {
      isPressUp: false,
      current: "00:00",
      isSpeedSelectorOpened: false,
      timeout: null
    }
  },
  computed: {
    getVolumeSize() {
      return this.$store.getters.getVideoSize
    },
    duration(){
      return this.videoTime(this.timeEnd)
    },
    currentValue(){
      if (this.current === 0){
        return 0
      }
      if (!this.isPressUp){
        return (Math.floor(this.currentTime) / (Math.floor(this.timeEnd) / 100))
      } else{
        return;
      }
    }
  },
  methods:{
    toggleVolume(){
      this.changeVolume(this.volume > 0 ? 0 : 1)
    },
    changeVolume(size){
      document.documentElement.style.setProperty('--range', (size * 100) + '%')
      this.$emit("changeVolume",size)
    },
    modifyPlayline(seconds){
      this.$emit("current",((this.timeEnd/100) * this.$refs.playline.value)+seconds)
    },
    onMouseUp(e){
      this.isPressUp = false
      console.log("up",e)
      this.$emit("current",(this.timeEnd/100) * e.target.value)
    },

    onMouseDown(e){
      this.isPressUp = true
      console.log("down",e)
    },

    toggleSpeedSelect(){
      console.log("toogle")
      this.isSpeedSelectorOpened = !this.isSpeedSelectorOpened
    },
    onChangeSpeed(speed){
      this.toggleSpeedSelect();
      this.$emit("changeSpeed",speed)
    },
    onChangeCurrent(e){
      console.log("change",e)
      let val = e.target.value
      document.documentElement.style.setProperty('--playline', val + '%')
      this.current = this.videoTime((this.timeEnd/100) * val)
    },
    videoTime(time) { //Рассчитываем время в секундах и минутах
      time = Math.floor(time);
      var minutes = Math.floor(time / 60);
      var seconds = Math.floor(time - minutes * 60);
      var minutesVal = minutes;
      var secondsVal = seconds;
      if(minutes < 10) {
        minutesVal = '0' + minutes;
      }
      if(seconds < 10) {
        secondsVal = '0' + seconds;
      }
      return minutesVal + ':' + secondsVal;
    },
    onTouchStart(id){
      return (direction,event) => {
        let e = document.getElementById(id)
        e.classList.add("speed__text_clicked")
      }
    },
    onTouchStop(id){
      return (direction,event) => {
        let e = document.getElementById(id)
        e.classList.remove("speed__text_clicked")
      }
    }
  },
  watch: {
    volume(size){
      console.log(size)
      document.documentElement.style.setProperty('--range', (size * 100) + '%')
    },
    currentTime(value){
      if (!this.isPressUp){
        this.current = this.videoTime(this.currentTime)
        console.log(this.$refs)
        this.$refs.playline.value = this.currentValue;
        document.documentElement.style.setProperty('--playline', this.currentValue + '%')
      }

    }
  },
  mounted() {
    document.documentElement.style.setProperty('--range', (this.volume * 100) + '%')
    document.documentElement.style.setProperty('--playline', (this.currentValue) + '%')


    // this.timeout = setTimeout(() => this.isVisible = false,3000)
    // window.addEventListener("mousemove", this.onMouseMove)
  },
  beforeDestroy() {
    // window.removeEventListener("mousemove")
  }
}
</script>

<style scoped lang="scss">
.interface {
  &__wrapper {
    &_mobile{
      &_vertical{
        background: rgba(55,55,55,0.80);
        border-radius: 14px;
        width: 320px;
        height: 85px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 60px;
        z-index: 999;
        transition: opacity 0.3s;
        margin: 0 auto;

      }
    }
  }
}

.interface-mobile-vertical{
  &__top{
    display: flex;
    margin-top: 9px;
  }


  &__back{
    margin-left: 97px;
  }

  &__play{
    margin-left: 30px;
  }

  &__next{
    margin-left: 30px;
  }

  &__speed{
    margin-left: 49px;
  }


  &__speed-label{
    width: 30px;
    height: 16px;
    font-family: Roboto, "sans-serif";
    font-weight: 500;
    cursor: pointer;
    font-size: 14px;
    color: #E2E2E2;
    text-align: right;
    line-height: 16px;
    margin-top: 2px;
    -webkit-tap-highlight-color: transparent;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;

  }


  &__playline{
    width: 284px;
    height: 10px;
    margin-top: 5px;
    margin-left: 18px;

    input {

      padding-top: 10px;
      padding-bottom: 10px;
      height: 24px;
      margin-top: -2px;
      display: inline-block;
      appearance: none;
      position: relative;
      width: 284px;
      cursor: pointer;
      //height: 4px;
      //background: #E2E2E2;
      //opacity: 0.1;
      background-color: transparent;
      border-radius: 2.45px;

      &::before {
        position: absolute;
        content: '';
        width: var(--playline);
        border-radius: 2.45px;
        height: 4px;
        background: #E2E2E2;
      }

      &::-webkit-slider-runnable-track {
        height: 4px;
        border-radius: 2.45px;
        background-color: rgba(226,226,226,.1);
      }

      &::-webkit-slider-thumb {
        height: 4px;
        width: 4px;
        appearance: none;
        border-radius: 2.45px;
        //background-color: #E2E2E2;


        background: rgb(226,226,226);
        width: 10px;
        height: 10px;
        border-radius: 5px;
        margin-top: -3.5px;
        margin-left: -1.5px;
        z-index: 2;
      }
    }

    label {
      background-color: #7e8bc6;
    }
  }


  &__bottom{
    margin-top: 10px;
    margin-left: 18px;
    margin-right: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 284px;
  }

  &__time{
    font-family: Roboto, "sans-serif";
    font-size: 9px;
    color: #E2E2E2;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }

}


.interface-mobile{
  &__top {
    position: absolute;
    top: 20px;
    left: 18px;
    z-index: 5;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__close {
    background: rgba(27, 27, 27, 0.796957);
    backdrop-filter: blur(8.15485px);
    width: 50px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 14px 0px 0px 14px;
    svg {
      vertical-align: middle;
    }
  }
  &__zoom {
    background: rgba(55, 55, 55, 0.8);
    backdrop-filter: blur(16.3097px);
    border-radius: 0px 14px 14px 0px;
    width: 50px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    &_hide{
      opacity: .5;
      pointer-events: none;
    }
  }
  &__audio {
    position: absolute;
    top: 20px;
    transition: all .25s;
    right: 18px;
    height: 40px;
    z-index: 5;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    flex-direction: row-reverse;
    background: rgba(40, 40, 40, 0.80324);
    backdrop-filter: blur(16.3097px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 14px;

    &._disabled{
      pointer-events: none;
      opacity: .25;
      background: none;
    }
  }
  &__wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    position: relative;
    z-index: 8;
  }
}

.speed{
  width: 30px;
  height: 16px;
  position: relative;

  &__select{
    position: absolute;
    width: 40px;
    background: rgba(55,55,55,0.80);
    border-radius: 8px;
    bottom: 38px;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 0;
    overflow: hidden;
    transition: height 0.3s;

    &_opened{
      height: 112px;
    }
  }

  &__text{
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 26px;
    margin-top: 9px;
    align-items: center;
    -webkit-tap-highlight-color: transparent;

    &_clicked{
      background-color: rgba(255,255,255,.2);
    }

    &:active{
      background-color: rgba(255,255,255,.2);
    }
  }
}


.hide{
  opacity: 0;
}
</style>
