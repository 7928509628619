<template>
  <svg
      ref="svg"
      v-touch:start="onTouchStart"
      v-touch:end="onTouchStop"
      width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>инструменты/ звук большой/ on</title>
    <g id="инструменты/-звук-большой/-on" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path d="M12.2061443,17.4619329 C12.2061443,17.8213086 11.9985001,18.1477945 11.673549,18.3001839 C11.54791,18.3596048 11.4132812,18.3883285 11.2795294,18.3883285 C11.0677191,18.3883285 10.8578823,18.3155324 10.6883903,18.1749834 L5.62380167,13.9782467 L2.92640064,13.9782467 C2.41485483,13.9786852 2.00000499,13.5636161 2.00000499,13.0520703 L2.00000499,9.36117844 C2.00000499,8.84941336 2.41485483,8.43456352 2.92640064,8.43456352 L5.62402093,8.43456352 L10.6886095,4.23782676 C10.9653223,4.00847532 11.3490364,3.95957917 11.6737683,4.11306484 C11.9985001,4.26545418 12.2063635,4.59215939 12.2063635,4.95131586 L12.2061443,17.4619329 L12.2061443,17.4619329 Z" id="Path-2" fill="#E2E2E2" fill-rule="nonzero"></path>
      <path class="sm-line" d="M2.32172489,3.26520155 C2.69632865,2.91941346 3.26421476,2.9143772 3.64400126,3.23483747 L3.73480844,3.32172489 L15.7348084,16.3217249 C16.1094122,16.7275456 16.0841058,17.3602047 15.6782851,17.7348084 C15.3036813,18.0805965 14.7357952,18.0856328 14.3560087,17.7651725 L14.2652015,17.6782851 L2.26520155,4.67828509 C1.89059779,4.27246436 1.91590415,3.6398053 2.32172489,3.26520155 Z" id="Path-3" stroke="#FFFFFF" stroke-width="0.5" fill="#E2E2E2" fill-rule="nonzero"></path>
      <path class="sm-audio1" d="M14.766354,16.0143437 C14.7437697,16.0158786 14.7220625,16.0167556 14.6996974,16.0167556 C14.4549974,16.0167556 14.219068,15.9200597 14.0447521,15.7455245 L13.9208673,15.6212011 C13.5959162,15.2969078 13.557764,14.7831694 13.831407,14.4143652 C14.5251623,13.4789797 14.8913352,12.3701554 14.8913352,11.2069533 C14.8913352,9.95582578 14.4756083,8.78231819 13.6888846,7.81316582 C13.3893683,7.44480021 13.4169957,6.90979302 13.7526908,6.57431719 L13.8763564,6.45043233 C14.0614163,6.26537247 14.3094053,6.16451045 14.5777859,6.18051682 C14.8391501,6.19367273 15.0831923,6.31668053 15.2489568,6.51928162 C16.3402399,7.85460695 16.9166883,9.47585416 16.9166883,11.2071725 C16.9166883,12.8196491 16.4068966,14.3545058 15.4421295,15.645101 C15.2809696,15.8602002 15.0345154,15.9952676 14.766354,16.0143437 Z" id="Path" fill="#E2E2E2" fill-rule="nonzero"></path>
      <path class="sm-audio2" d="M18.5964793,18.87729 C18.4289607,19.0752865 18.1866726,19.1941283 17.9272818,19.2050915 C17.9145644,19.2055301 17.9016277,19.2059686 17.8884718,19.2059686 C17.643114,19.2059686 17.4076232,19.1090534 17.2333073,18.9347375 L17.1116151,18.8130453 C16.7715347,18.4731842 16.7485118,17.9296256 17.0576758,17.5616985 C18.5517492,15.784773 19.3748709,13.5280951 19.3748709,11.2069533 C19.3748709,8.79262366 18.4936439,6.46841208 16.894104,4.66254354 C16.5698107,4.29593205 16.5862555,3.74097172 16.931379,3.39453263 L17.052852,3.27284042 C17.233088,3.09172733 17.4672633,2.99196165 17.7358632,3.00051299 C17.9908687,3.00774875 18.2320605,3.12045108 18.4015525,3.31121184 C20.3350334,5.48807718 21.4000048,8.29247964 21.4000048,11.2069533 C21.4004432,14.0111365 20.4047598,16.7352878 18.5964793,18.87729 Z" id="Path" fill="#E2E2E2" fill-rule="nonzero"></path>
    </g>
  </svg>
</template>

<script>
import anime from 'animejs/lib/anime.es.js';
export default {
  name: "AudioBigSvg",
  props:{
    isAudio: Boolean
  },
  data(){
    return {
      tl: null
    }
  },
  methods:{
    onTouchStart(){
      this.$refs.svg.style.opacity = .8
    },
    onTouchStop(){
      this.$refs.svg.style.opacity = 1
    }
  },
  watch:{
    async isAudio(val){
      if(val){

        anime({
          targets: '.sm-line',
          scale: 0,
          easing: 'easeOutExpo',
          delay: 0,
          duration: 250
        });

        anime({
          targets: '.sm-audio1',
          opacity: 1,
          easing: 'easeOutExpo',
          delay: 0,
          duration: 1000
        });

        anime({
          targets: '.sm-audio2',
          opacity: 1,
          easing: 'easeOutExpo',
          delay: 0,
          duration: 1000
        });
      } else {
        anime({
          targets: '.sm-audio2',
          opacity: 0,
          easing: 'easeOutExpo',
          delay: 0,
          duration: 1000
        });

        anime({
          targets: '.sm-audio1',
          opacity: 0,
          easing: 'easeOutExpo',
          delay: 0,
          duration: 1000
        });

        anime({
          targets: '.sm-line',
          scale: 1,
          easing: 'easeOutExpo',
          delay: 0,
          duration: 250
        });
      }
    }
  }
}
</script>

<style scoped>
.sm-line{
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

svg{
  -webkit-tap-highlight-color: transparent;

}

.sm-audio1{
  opacity: 0;
}

.sm-audio2{
  opacity: 0;
}
</style>
