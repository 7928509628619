<template>
  <svg
      ref="svg"
      v-touch:start="onTouchStart"
      v-touch:end="onTouchStop"
      width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>инструменты/ перемотка/назад</title>
    <g id="инструменты/-перемотка/назад" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group-4" transform="translate(3.000000, 1.177642)">
        <text id="15" font-family="Roboto-Medium, Roboto" font-size="9" font-weight="400" letter-spacing="-0.6" fill="#E2E2E2">
          <tspan x="3" y="14">15</tspan>
        </text>
        <path d="M8.5,19.5 C13.1944204,19.5 17,15.6944204 17,11 C17,6.30557963 13.1944204,2.5 8.5,2.5 C3.80557963,2.5 0,6.30557963 0,11" id="Oval" stroke="#D8D8D8" stroke-width="1.5" transform="translate(8.500000, 11.000000) rotate(90.000000) translate(-8.500000, -11.000000) "></path>
        <polygon id="Rectangle" fill="#D8D8D8" points="9 0 9 5 5 2.5"></polygon>
        <polygon id="Rectangle" fill="#D8D8D8" points="6 0 6 5 2 2.5"></polygon>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "PreviousSvg",
  methods:{
    onTouchStart(){
      this.$refs.svg.style.opacity = .8
    },
    onTouchStop(){
      this.$refs.svg.style.opacity = 1
    }
  }
}
</script>

<style scoped>
svg:hover{
  opacity: .8;
}

svg{
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

</style>
