<template>
  <svg
      ref="svg"
      v-touch:start="onTouchStart"
      v-touch:end="onTouchStop"
      width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="инструменты/-лупа-/актив" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path d="M7.4825447,0 C9.48210047,0 11.3620877,0.780525986 12.7759825,2.19780839 C15.1893429,4.61684408 15.6070831,8.29010645 14.029578,11.1436443 L17.4067957,14.5288666 C18.1977348,15.3216656 18.1977348,16.6116468 17.4067957,17.4044458 C17.0236642,17.788541 16.5142797,18 15.9724106,18 C15.4305415,18 14.921157,17.788541 14.5380254,17.4044458 L11.1758007,14.0343143 C10.0623265,14.6693175 8.79836084,15.0073262 7.4825447,15.0073262 C5.48292647,15.0073262 3.60300171,14.2268629 2.18910688,12.8095805 C-0.729702293,9.88384471 -0.729702293,5.12348153 2.18910688,2.19780839 C3.60306418,0.780525986 5.48298894,0 7.4825447,0 Z M7.48248223,1.87852223 C5.98350258,1.87852223 4.5741681,2.46361929 3.51423113,3.52611146 C1.32620186,5.71934878 1.32620186,9.28804008 3.51423113,11.4812774 C4.57423057,12.543707 5.98350258,13.128804 7.48248223,13.128804 C8.98146188,13.128804 10.3907964,12.543707 11.4507333,11.4812774 C13.6387626,9.28804008 13.6387626,5.71928616 11.4507333,3.52611146 C10.3907339,2.46361929 8.98146188,1.87852223 7.48248223,1.87852223 Z M9.82922196,6.92413052 C10.1996824,6.92413052 10.5,7.25991696 10.5,7.67413052 C10.5,8.05382629 10.2476498,8.36762148 9.92024257,8.4172839 L9.82922196,8.42413052 L5.17077804,8.42413052 C4.80031756,8.42413052 4.5,8.08834408 4.5,7.67413052 C4.5,7.29443475 4.75235017,6.98063956 5.07975743,6.93097714 L5.17077804,6.92413052 L9.82922196,6.92413052 Z" id="Combined-Shape" fill="#E2E2E2" fill-rule="nonzero"></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: "ZoomSvg",
  methods:{
    onTouchStart(){
      this.$refs.svg.style.opacity = .8
    },
    onTouchStop(){
      this.$refs.svg.style.opacity = 1
    }
  }
}
</script>

<style scoped>
svg:hover{
  opacity: .8;
}

svg{
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
</style>
