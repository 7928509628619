<template>
  <div
      class="filter"
      :style="computedStyle">

  </div>
</template>

<script>
export default {
  name: "FilterObject",
  props:{
    left: String,
    top: String,
    width: String,
    height: String,
    isRound: Boolean
  },
  computed:{
    computedStyle(){
      return {
        left:this.left,
        top:this.top,
        width: this.width,
        height: this.height,
        borderRadius: (this.isRound) ? '50%' : 0
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .filter{
    position: absolute;
    z-index: 1000;
    pointer-events: none;
    @supports (backdrop-filter: blur(10px)) {
      backdrop-filter:blur(10px);
    }

    @supports not (backdrop-filter: blur(10px)) {
      background: rgba(0,0,0,.92);
    }
}
</style>
