<template>
  <div class="interface__wrapper interface__wrapper_desktop" :class="{'hide' : !isVisible} ">
    <div class="interface__top">
      <inteface-finite-audio
          class="interface__audio"
          :volume="volume"
          :has-audio="hasAudio"
          @changeVideoVolume="changeVideoVolume"
          @changeVolume="changeVolume"></inteface-finite-audio>
      <PreviousSvg
        class="interface__back"
        @click.native="modifyPlayline(-15)"/>
      <StopSvg
          class="interface__play"
          v-if="!stop"
          @click.native="$emit('changePlay', true)"
      />
      <PlaySvg
          class="interface__play"
          v-if="stop"
          @click.native="$emit('changePlay', false)"
      />
      <NextSvg
          class="interface__next"
          @click.native="modifyPlayline(15)"
      />

      <div class="interface__speed speed">
        <div class="speed__select"
             :class="{'speed__select_opened' : isSpeedSelectorOpened}">
          <div class="speed__text" @click="onChangeSpeed(2)"><TwoXSpeedSvg/></div>
          <div class="speed__text" @click="onChangeSpeed(1.5)"><PoltoraXSpeedSvg/></div>
          <div class="speed__text" @click="onChangeSpeed(1)"><OneXSpeedSvg/></div>
        </div>
        <p class="interface__speed-label" @click="toggleSpeedSelect">{{speed}}x</p>
      </div>

      <div class="interface__zoom" :class="zoom === 1 ? 'interface__zoom_hide' : ''" @click="$emit('changeZoom', 1)">
        <ZoomSvg/>
      </div>
      <ExitSvg
          class="interface__exit"
          @click.native="$emit('exitScreen')"
      />
    </div>
    <div class="interface__bottom">
      <p class="interface__time">{{current}}</p>
      <div class="interface__playline">
        <input
            ref="playline"
            type="range"
            min="0"
            max="100"
            value="0"
            step="0.01"
            id="range"
            @input="onChangeCurrent"
            @mouseup="onMouseUp"
            @mousedown="onMouseDown"
        >
        <label for="range"></label>
      </div>
      <p class="interface__time">{{duration}}</p>
    </div>
  </div>
</template>

<script>
import PreviousSvg from "../../assets/vue-icons/Pleer/PreviousSvg";
import StopSvg from "../../assets/vue-icons/Pleer/StopSvg";
import NextSvg from "../../assets/vue-icons/Pleer/NextSvg";
import OneXSpeedSvg from "../../assets/vue-icons/Pleer/OneXSpeedSvg";
import PoltoraXSpeedSvg from "../../assets/vue-icons/Pleer/PoltoraXSpeedSvg";
import TwoXSpeedSvg from "../../assets/vue-icons/Pleer/TwoXSpeedSvg";
import ZoomSvg from "../../assets/vue-icons/Pleer/ZoomSvg";
import ExitSvg from "../../assets/vue-icons/Pleer/ExitSvg";
import PlaySvg from "../../assets/vue-icons/Pleer/PlaySvg";
import IntefaceFiniteAudio from "./interface/InterfaceFiniteAudio";
export default {
  name: "VideoFiniteInterfaceDesktop",
  components: {
    IntefaceFiniteAudio,
    PlaySvg,
    ExitSvg,
    ZoomSvg,
    TwoXSpeedSvg,
    PoltoraXSpeedSvg,
    OneXSpeedSvg, NextSvg, StopSvg, PreviousSvg},
  props:{
    video: Object,
    stop: Boolean,
    zoom: Number,
    volume: Number,
    hasAudio: Boolean,
    timeEnd: Number,
    currentTime: Number,
    speed: Number,
    isVisible: Boolean
  },
  data(){
    return {
      isPressUp: false,
      current: "00:00",
      isSpeedSelectorOpened: false,
      timeout: null,
    }
  },
  computed: {
    getVolumeSize() {
      return this.$store.getters.getVideoSize
    },
    duration(){
      return this.videoTime(this.timeEnd)
    },
    currentValue(){
      if (this.current === 0){
        return 0
      }
      if (!this.isPressUp){
        return (Math.floor(this.currentTime) / (Math.floor(this.timeEnd) / 100))
      } else{
        return;
      }
    }
  },
  methods: {
    exitFullScreen(){
      this.$emit("exitScreen")
    },
    play(isPlay){
      return (direction,event) =>{
        this.$emit('changePlay', isPlay)
      }
    },
    changeAudioSize(e) {
      const val = e.target.value
      this.$store.commit('changeVideoVolume', val)
      let hasAudioPlayer = this.$store.getters.getVideo.volume
      if (val) {
        if (!hasAudioPlayer) {
          this.changeVideoVolume(true)
        }
      } else {
        if (hasAudioPlayer) {
          this.changeVideoVolume(false)
        }
      }
    },
    changeVideoVolume(type) {
      if (type) {
        this.$store.commit('changeVideo', {volume: this.video.id, volumeSize: 1})
      } else {
        this.$store.commit('changeVideo', {volume: null, volumeSize: 0})
      }

    },
    changeVolume(size){
      document.documentElement.style.setProperty('--range', (size * 100) + '%')
      this.$emit("changeVolume",size)
    },
    videoTime(time) { //Рассчитываем время в секундах и минутах
      time = Math.floor(time);
      var minutes = Math.floor(time / 60);
      var seconds = Math.floor(time - minutes * 60);
      var minutesVal = minutes;
      var secondsVal = seconds;
      if(minutes < 10) {
        minutesVal = '0' + minutes;
      }
      if(seconds < 10) {
        secondsVal = '0' + seconds;
      }
      return minutesVal + ':' + secondsVal;
    },

    onChangeCurrent(e){
      console.log("change",e)
      let val = e.target.value
      document.documentElement.style.setProperty('--playline', val + '%')
      this.current = this.videoTime((this.timeEnd/100) * val)
    },

    onMouseUp(e){
      this.isPressUp = false
      console.log("up",e)
      this.$emit("current",(this.timeEnd/100) * e.target.value)
    },

    onMouseDown(e){
      this.isPressUp = true
      console.log("down",e)
    },

    toggleSpeedSelect(){
      console.log("toogle")
      this.isSpeedSelectorOpened = !this.isSpeedSelectorOpened
    },

    onChangeSpeed(speed){
      this.toggleSpeedSelect();
      this.$emit("changeSpeed",speed)
    },

    modifyPlayline(seconds){
      this.$emit("current",((this.timeEnd/100) * this.$refs.playline.value)+seconds)
    },

  },
  watch: {
    volume(size){
      console.log(size)
      document.documentElement.style.setProperty('--range', (size * 100) + '%')
    },
    currentTime(value){
      if (!this.isPressUp){
        this.current = this.videoTime(this.currentTime)
        this.$refs.playline.value = this.currentValue;
        document.documentElement.style.setProperty('--playline', this.currentValue + '%')
      }

    }
  },
  mounted() {
    document.documentElement.style.setProperty('--range', (this.volume * 100) + '%')
    document.documentElement.style.setProperty('--playline', (this.currentValue) + '%')
  },
  beforeDestroy() {
    // window.removeEventListener("mousemove")
  }
}
</script>

<style scoped lang="scss">
.interface {
  &__wrapper {
    opacity: 1;
    transition: opacity .3s;
    &_desktop{
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: 10px;
      width: 520px;
      height: 85px;
      background: rgba(55,55,55,0.80);
      border-radius: 14px;
      backdrop-filter: blur(3px);
      z-index: 999;
    }
  }


  &__top{
    display: flex;
    margin-top: 10px;
  }

  &__playline{
    width: 420px;
    height: 8px;
    margin-left: 7px;
    margin-right: 7px;
    display: flex;

    input {
      display: inline-block;
      appearance: none;
      position: relative;
      width: 420px;
      cursor: pointer;
      height: 4px;
      //background: #E2E2E2;
      //opacity: 0.1;
      background-color: transparent;
      border-radius: 2.45px;

      &::before {
        position: absolute;
        content: '';
        width: var(--playline);
        border-radius: 2.45px;
        height: 4px;
        background: #E2E2E2;
      }

      &::-webkit-slider-runnable-track {
        height: 4px;
        border-radius: 2.45px;
        background-color: rgba(226,226,226,.1);
      }

      &::-webkit-slider-thumb {
        height: 4px;
        width: 4px;
        appearance: none;
        border-radius: 2.45px;
        //background-color: #E2E2E2;


        background: rgb(226,226,226);
        width: 10px;
        height: 10px;
        border-radius: 5px;
        margin-top: -3.5px;
        margin-left: -1.5px;
        z-index: 2;
      }
    }

    label {
      background-color: #7e8bc6;
    }
  }

  &__bottom{
    margin-top: 20px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    width: 480px;
  }

  &__time{
    font-family: Roboto, "sans-serif";
    font-size: 9px;
    color: #E2E2E2;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }


  &__speed-label{
    width: 36px;
    height: 18px;
    font-family: Roboto, "sans-serif";
    font-weight: 500;
    font-size: 18px;
    color: #E2E2E2;
    text-align: right;
    line-height: 18px;
    cursor: pointer;


    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;


    &:hover{
      opacity: .8;
    }
  }


  &__audio{
    margin-left: 18px;
    width: 76px;
  }

  &__back{
    margin-left: 93px;
  }

  &__play {
    margin-left: 40px;
  }

  &__next {
    margin-left: 40px;
  }

  &__speed{
    margin-left: 35px;
  }

  &__zoom{
    margin-left: 30px;

    &_hide {
      opacity: .5;
      pointer-events: none;
    }
  }

  &__exit{
    margin-left: 30px;
  }

}

.speed{
  width: 36px;
  height: 18px;
  position: relative;

  &__select{
    position: absolute;
    width: 40px;
    background: rgba(55,55,55,0.80);
    border-radius: 8px;
    bottom: 38px;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 0;
    overflow: hidden;
    transition: height 0.3s;

    &_opened{
      height: 112px;
    }
  }

  &__text{
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 26px;
    margin-top: 9px;
    align-items: center;


    &:active{
      background-color: rgba(255,255,255,.2);
    }
  }
}

.hide{
  opacity: 0;
}
</style>
