<template>
  <svg
      ref="svg"
      v-touch:start="onTouchStart"
      v-touch:end="onTouchStop"
      width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>инструменты/ Х</title>
    <g id="инструменты/-Х" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" fill-opacity="0.8">
      <path d="M15.9273874,1.84846772 L16.0451076,1.95489243 C16.6467471,2.55653193 16.6494552,3.52927421 16.0405613,4.13816807 L11.179,9 L16.0405613,13.8618319 C16.6069522,14.4282229 16.6478272,15.3190541 16.1515323,15.9273874 L16.0451076,16.0451076 C15.4434681,16.6467471 14.4707258,16.6494552 13.8618319,16.0405613 L9,11.179 L4.13816807,16.0405613 C3.57177713,16.6069522 2.6809459,16.6478272 2.07261261,16.1515323 L1.95489243,16.0451076 C1.35325293,15.4434681 1.35054484,14.4707258 1.9594387,13.8618319 L6.821,9 L1.9594387,4.13816807 C1.39304776,3.57177713 1.35217278,2.6809459 1.84846772,2.07261261 L1.95489243,1.95489243 C2.55653193,1.35325293 3.52927421,1.35054484 4.13816807,1.9594387 L9,6.821 L13.8618319,1.9594387 C14.4282229,1.39304776 15.3190541,1.35217278 15.9273874,1.84846772 Z" id="Combined-Shape" fill="#FFFFFF"></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: "ExitSvg",
  methods:{
    onTouchStart(){
      this.$refs.svg.style.opacity = .8
    },
    onTouchStop(){
      this.$refs.svg.style.opacity = 1
    }
  }
}
</script>

<style scoped>
svg:hover{
  opacity: .8;
}

svg{
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
</style>
