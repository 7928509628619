<template>
  <svg width="15px" height="10px" viewBox="0 0 15 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>1x 2</title>
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="инструменты/-Скорость-воспроизведения-/-окошечко" transform="translate(-13.000000, -82.000000)" fill="#E2E2E2" fill-rule="nonzero">
        <g id="1x-2" transform="translate(13.000000, 82.000000)">
          <path d="M4.09472656,9.98730469 L4.09472656,0 L3.8828125,0 L0,1.42871094 L0,2.83007812 L2.44042969,1.99609375 L2.44042969,9.98730469 L4.09472656,9.98730469 Z M9.86621094,9.98730469 L11.3359375,7.45800781 L12.8125,9.98730469 L14.6445312,9.98730469 L12.3066406,6.234375 L14.5693359,2.59082031 L12.7236328,2.59082031 L11.3154297,5.02441406 L9.93457031,2.59082031 L8.10253906,2.59082031 L10.3583984,6.234375 L8.02050781,9.98730469 L9.86621094,9.98730469 Z" id="1x"></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "OneXSpeedSvg"
}
</script>

<style scoped>
svg:hover{
  opacity: .8;
}

svg{
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
</style>
